const initState = {
    classLoader: 'hideLoader',
    loading: false,
    progress: '0%'
}

const Loader = (state = initState, action)=>{
    if(action.type === 'TOGGLE_LOADER'){
        let classLoader = '';
        if(action.loading){
            classLoader = 'loadingPhoto';
        }else{
            classLoader = 'hideLoader';
        }
        return{ ...state, classLoader, loading: action.loading }
    }else if(action.type === 'UPDATE_PROGRESS'){
        return{ ...state, progress: action.progress }
    }else{
        return state;
    }
}

export default Loader;
