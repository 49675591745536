import React, { Suspense, lazy } from "react";
// noinspection ES6CheckImport
import { BrowserRouter as Router, Route } from "react-router-dom";
const Loading = lazy(() =>
  import(
    /* webpackChunkName: "Loading" */ /* webpackPrefetch: true */ "./Loading"
  )
);
const PrivateRoute = lazy(() =>
  import(
    /* webpackChunkName: "PrivateRoute" */ /* webpackPrefetch: true */ "./administrar/components/PrivateRoute"
  )
);
const ProtectedRoute = lazy(() =>
  import(
    /* webpackChunkName: "ProtectedRoute" */ /* webpackPrefetch: true */ "./sitio-web/components/ProtectedRoute"
  )
);
const HomeWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "HomeWebContainer" */ /* webpackPreload: true */ "./sitio-web/containers/home/HomeWebContainer"
  )
);
const LoginContainer = lazy(() =>
  import(
    /* webpackChunkName: "LoginContainer" */ /* webpackPrefetch: true */ "./administrar/containers/login/LoginContainer"
  )
);
const PanelContainer = lazy(() =>
  import(
    /* webpackChunkName: "PanelContainer" */ /* webpackPrefetch: true */ "./administrar/containers/panel/PanelContainer"
  )
);
const EditCategoryContainer = lazy(() =>
  import(
    /* webpackChunkName: "EditCategoryContainer" */ /* webpackPrefetch: true */ "./administrar/containers/categories/EditCategoryContainer"
  )
);
const NewCategoryContainer = lazy(() =>
  import(
    /* webpackChunkName: "NewCategoryContainer" */ /* webpackPrefetch: true */ "./administrar/containers/categories/NewCategoryContainer"
  )
);
const CategoryProductsContainer = lazy(() =>
  import(
    /* webpackChunkName: "CategoryProductsContainer" */ /* webpackPrefetch: true */ "./administrar/containers/categories/CategoryProductsContainer"
  )
);
const ContactContainer = lazy(() =>
  import(
    /* webpackChunkName: "ContactContainer" */ /* webpackPrefetch: true */ "./administrar/containers/contact/ContactContainer"
  )
);
const ViewMessageContainer = lazy(() =>
  import(
    /* webpackChunkName: "ViewMessageContainer" */ /* webpackPrefetch: true */ "./administrar/containers/contact/ViewMessageContainer"
  )
);
const ClientsContainer = lazy(() =>
  import(
    /* webpackChunkName: "ClientsContainer" */ /* webpackPrefetch: true */ "./administrar/containers/clients/ClientsContainer"
  )
);
const OrdersContainer = lazy(() =>
  import(
    /* webpackChunkName: "OrdersContainer" */ /* webpackPrefetch: true */ "./administrar/containers/orders/OrdersContainer"
  )
);
const CategoryArticleContainer = lazy(() =>
  import(
    /* webpackChunkName: "CategoryArticleContainer" */ /* webpackPrefetch: true */ "./administrar/containers/articles/CategoryArticleContainer"
  )
);
const NewCategoryArticleContainer = lazy(() =>
  import(
    /* webpackChunkName: "NewCategoryArticleContainer" */ /* webpackPrefetch: true */ "./administrar/containers/articles/NewCategoryArticleContainer"
  )
);
const EditCategoryArticleContainer = lazy(() =>
  import(
    /* webpackChunkName: "EditCategoryArticleContainer" */ /* webpackPrefetch: true */ "./administrar/containers/articles/EditCategoryArticleContainer"
  )
);
const ArticlesContainer = lazy(() =>
  import(
    /* webpackChunkName: "ArticlesContainer" */ /* webpackPrefetch: true */ "./administrar/containers/articles/ArticlesContainer"
  )
);
const NewArticleContainer = lazy(() =>
  import(
    /* webpackChunkName: "NewArticleContainer" */ /* webpackPrefetch: true */ "./administrar/containers/articles/NewArticleContainer"
  )
);
const EditArticleContainer = lazy(() =>
  import(
    /* webpackChunkName: "EditArticleContainer" */ /* webpackPrefetch: true */ "./administrar/containers/articles/EditArticleContainer"
  )
);
const CommentsContainer = lazy(() =>
  import(
    /* webpackChunkName: "CommentsContainer" */ /* webpackPrefetch: true */ "./administrar/containers/articles/CommentsContainer"
  )
);
const ProductsContainer = lazy(() =>
  import(
    /* webpackChunkName: "ProductsContainer" */ /* webpackPrefetch: true */ "./administrar/containers/products/ProductsContainer"
  )
);
const NewProductContainer = lazy(() =>
  import(
    /* webpackChunkName: "NewProductContainer" */ /* webpackPrefetch: true */ "./administrar/containers/products/NewProductContainer"
  )
);
const EditProductContainer = lazy(() =>
  import(
    /* webpackChunkName: "EditProductContainer" */ /* webpackPrefetch: true */ "./administrar/containers/products/EditProductContainer"
  )
);
const PromotionsContainer = lazy(() =>
  import(
    /* webpackChunkName: "PromotionsContainer" */ /* webpackPrefetch: true */ "./administrar/containers/promotions/PromotionsContainer"
  )
);
const NewPromotionContainer = lazy(() =>
  import(
    /* webpackChunkName: "NewPromotionContainer" */ /* webpackPrefetch: true */ "./administrar/containers/promotions/NewPromotionContainer"
  )
);
const EditPromotionContainer = lazy(() =>
  import(
    /* webpackChunkName: "EditPromotionContainer" */ /* webpackPrefetch: true */ "./administrar/containers/promotions/EditPromotionContainer"
  )
);
const ContactWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "ContactWebContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/contact/ContactWebContainer"
  )
);
const BlogWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "BlogWebContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/blog/BlogWebContainer"
  )
);
const ProductsWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "ProductsWebContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/products/ProductsWebContainer"
  )
);
const ArticleWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "ArticleWebContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/blog/ArticleWebContainer"
  )
);
const HistoryWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "HistoryWebContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/about-us/HistoryWebContainer"
  )
);
const AboutWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "AboutWebContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/about-us/AboutWebContainer"
  )
);
const ProductWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "ProductWebContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/products/ProductWebContainer"
  )
);
const CartContainer = lazy(() =>
  import(
    /* webpackChunkName: "CartContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/cart/CartContainer"
  )
);
const CheckoutContainer = lazy(() =>
  import(
    /* webpackChunkName: "CheckoutContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/cart/CheckoutContainer"
  )
);
const ClientLoginContainer = lazy(() =>
  import(
    /* webpackChunkName: "ClientLoginContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/client_area/ClientLoginContainer"
  )
);
const ClientOrdersContainer = lazy(() =>
  import(
    /* webpackChunkName: "ClientOrdersContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/client_area/ClientOrdersContainer"
  )
);
const PrivacyPolicyContainer = lazy(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/cart/PrivacyPolicyContainer"
  )
);
const OxxoContainer = lazy(() =>
  import(
    /* webpackChunkName: "OxxoContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/cart/OXXOContainer"
  )
);

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Route exact path="/" component={HomeWebContainer} />
        <Route exact path="/quienes-somos" component={AboutWebContainer} />
        <Route exact path="/historia" component={HistoryWebContainer} />
        <Route
          exact
          path="/nuestros-productos"
          component={ProductsWebContainer}
        />
        <Route exact path="/producto/:name" component={ProductWebContainer} />
        <Route exact path="/blog" component={BlogWebContainer} />
        <Route exact path="/articulo/:name" component={ArticleWebContainer} />
        <Route exact path="/contacto" component={ContactWebContainer} />
        <Route exact path="/carrito" component={CartContainer} />
        <Route exact path="/finalizar-compra" component={CheckoutContainer} />
        <Route exact path="/recibo-oxxo" component={OxxoContainer} />
        <Route
          exact
          path="/politica-privacidad"
          component={PrivacyPolicyContainer}
        />
        <Route exact path="/iniciar-sesion" component={ClientLoginContainer} />
        <ProtectedRoute
          exact
          path="/mis-pedidos"
          component={ClientOrdersContainer}
        />
        <Route exact path="/administrar" component={LoginContainer} />
        <PrivateRoute exact path="/panel" component={PanelContainer} />
        <PrivateRoute
          exact
          path="/categorias-productos"
          component={CategoryProductsContainer}
        />
        <PrivateRoute
          exact
          path="/nueva-categoria"
          component={NewCategoryContainer}
        />
        <PrivateRoute
          exact
          path="/editar-categoria"
          component={EditCategoryContainer}
        />
        <PrivateRoute
          exact
          path="/mensajes-contacto"
          component={ContactContainer}
        />
        <PrivateRoute
          exact
          path="/ver-mensaje"
          component={ViewMessageContainer}
        />
        <PrivateRoute exact path="/clientes" component={ClientsContainer} />
        <PrivateRoute exact path="/pedidos" component={OrdersContainer} />
        <PrivateRoute exact path="/productos" component={ProductsContainer} />
        <PrivateRoute
          exact
          path="/nuevo-producto"
          component={NewProductContainer}
        />
        <PrivateRoute
          exact
          path="/editar-producto"
          component={EditProductContainer}
        />
        <PrivateRoute
          exact
          path="/categorias-articulos"
          component={CategoryArticleContainer}
        />
        <PrivateRoute
          exact
          path="/nueva-categoria-articulo"
          component={NewCategoryArticleContainer}
        />
        <PrivateRoute
          exact
          path="/editar-categoria-articulo"
          component={EditCategoryArticleContainer}
        />
        <PrivateRoute exact path="/articulos" component={ArticlesContainer} />
        <PrivateRoute
          exact
          path="/nuevo-articulo"
          component={NewArticleContainer}
        />
        <PrivateRoute
          exact
          path="/editar-articulo"
          component={EditArticleContainer}
        />
        <PrivateRoute
          exact
          path="/ver-comentarios"
          component={CommentsContainer}
        />
        <PrivateRoute
          exact
          path="/promociones"
          component={PromotionsContainer}
        />
        <PrivateRoute
          exact
          path="/nueva-promocion"
          component={NewPromotionContainer}
        />
        <PrivateRoute
          exact
          path="/editar-promocion"
          component={EditPromotionContainer}
        />
      </Router>
    </Suspense>
  );
}

export default App;
