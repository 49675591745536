const initState = {
  activeImage: null,
  activeGallery: [],
  editImage: null,
  editGallery: [],
  updatedGallery: []
};

const Photo = (state = initState, action) => {
  if(action.type === 'SET_ACTIVE_IMAGE'){
    return{ ...state, activeImage: action.image }
  }else if(action.type === 'SET_ACTIVE_GALLERY'){
    return{ ...state, activeGallery: action.images }
  }else if(action.type === 'SET_EDIT_IMAGE'){
    return{ ...state, editImage: action.image }
  }else if(action.type === 'SET_EDIT_GALLERY'){
    return{ ...state, editGallery: action.images }
  }else if(action.type === 'SET_UPDATED_GALLERY'){
    return{ ...state, updatedGallery: action.images }
  }else{
    return state;
  }
};

export default Photo;
