import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import Loader from "./Loader";
import TextEditor from "./TextEditor";
import Photo from "./Photo";
import Menu from "./Menu";

export default combineReducers({
  form: formReducer,
  loader: Loader,
  editor: TextEditor,
  photo: Photo,
  menu: Menu
});
